import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Forte = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #B9A013, #DABD15, #EDCD16, #DABD15, #DABD15 )",
        icons: "#fed412",
        navClass: "forte",
      }}
      seo={{
        title: "Forte",
        headerTitle: "forte",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/forte_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/forte/",
      }}
    >
      <PortfolioHeader name="forte" height="278" />
      <section className="container-fluid forte_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Forte</h1>
              <ul>
                <li>Online Store</li>
                <li>Competition entry</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                FORTE is one of the largest European manufacturers of
                self-assembly furniture. The furniture is designed with passion
                by Polish and foreign designers and the design is adapted to
                changing market trends and the expectations of the most
                demanding customers.
              </p>
              <h3>Goal:</h3>
              <ol>
                <li>New online store</li>
                <li>
                  Presenting the FORTE brand as a modern and innovative leader
                  on both Polish and European markets
                </li>
              </ol>
              <h3>Result of our work:</h3>
              <p>
                We have designed a convenient and effective online store, based
                on the existing visual identity of the brand. The project was
                not implemented because the customer gave up the decision to
                change the online store.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid forte_section_3">
        <div className="row row_1">
          <div className="col-md-6 offset-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/forte_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row_2">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/forte_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_3">
          <div className="col-md-7 offset-md-5 text-right">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/forte_main_3.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/forte_main_4.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_5">
          <div className="col-md-3 offset-md-9 text-right">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/forte_main_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Forte;
